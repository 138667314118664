/** @jsx jsx */
import { jsx, Container, Flex, Box, Heading, Paragraph } from "theme-ui"
import { StaticImage } from "gatsby-plugin-image"

// src = "../../images/homepage/side-image-2.png"

const HomepageSideImageSection = () => {
  return (
    <section
      sx={{
        pt: 4
      }}
    >
      <Container variant="homepageRightSideImage">
        <Flex
          sx={{
            flexDirection: ["column", "row"],
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Box
            sx={{
              maxWidth: "503px",
              mr: 3
            }}
          >
            <Heading as="h3" variant="styles.h3">
              Connect with customers. Share your story.
            </Heading>
            <Paragraph
              sx={{
                mt: 4
              }}
            >
              We know! The world needs your product. We get it in front of the
              right people better than anyone.
            </Paragraph>
          </Box>
          <StaticImage
            src="../../images/homepage/side-image-1.png"
            alt=""
            width={720}
            sx={{
              mt: [4, 0],
              alignSelf: ["flex-end", "center"]
            }}
          />
        </Flex>
      </Container>

      <Container
        variant="homepageLeftSideImage"
        sx={{
          mt: [0, -7]
        }}
      >
        <Flex
          sx={{
            flexDirection: ["column-reverse", "row"],
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <StaticImage
            src="../../images/homepage/side-image-2.png"
            alt=""
            width={720}
            sx={{
              mt: [4, 0],
              alignSelf: ["flex-start", "center"]
            }}
          />
          <Box
            sx={{
              maxWidth: "503px",
              ml: 3
            }}
          >
            <Heading as="h3" variant="styles.h3">
              How?
            </Heading>
            <Paragraph
              sx={{
                mt: 4
              }}
            >
              Proven creative advertising and marketing through technology,
              strategy and branded content.
            </Paragraph>
          </Box>
        </Flex>
      </Container>
    </section>
  )
}

export default HomepageSideImageSection
