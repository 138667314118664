/** @jsx jsx */
import { jsx, Container, Flex, Box, Heading } from "theme-ui"
import { theme } from "gatsby-plugin-theme-ui/index"
import { useStaticQuery, graphql, Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import ServiceCardList from "components/homepage/ServiceCardList"
import MoveYourBrandCard from "components/Footer/MoveYourBrandCard"

const HomepageGradientSection = () => {
  const data = useStaticQuery(graphql`
    query HomepageGradientSection {
      strapiMoveForwardPdf {
        file {
          localFile {
            publicURL
          }
        }
      }
    }
  `)

  return (
    <section
      sx={{
        mt: [0, -5],
        pt: [5, 0],
        background: "linear-gradient(to top, #1A8FAB 0%, #FFFFFF 85%)"
      }}
    >
      <Container
        variant="container1200"
        sx={{
          color: "themeBlack"
        }}
      >
        <Flex
          sx={{
            flexDirection: ["column", "row"],
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          <Box
            sx={{
              maxWidth: "430px",
              color: "themeBlack"
            }}
          >
            <Heading as="h3" variant="styles.h3">
              Mōve your brand forward now!
            </Heading>
            <ol
              sx={{
                p: 0,
                listStyle: "none"
              }}
            >
              <li>
                <Heading as="h5" variant="styles.h5">
                  1.{" "}
                  <a
                    href={data.strapiMoveForwardPdf.file.localFile.publicURL}
                    target="_blank"
                    sx={theme?.links?.download}
                  >
                    Download
                  </a>{" "}
                  our free Mōve Forward PDF
                </Heading>
              </li>
              <li>
                <Heading as="h5" variant="styles.h5">
                  2. Learn about our services
                </Heading>
              </li>
              <li>
                <Heading as="h5" variant="styles.h5">
                  3. Contact us today
                </Heading>
              </li>
            </ol>
            <Link sx={theme?.links?.arrow} to="/contact">
              Get in touch
            </Link>
          </Box>
          <StaticImage
            sx={{
              mt: [4, 0]
            }}
            src="../../images/image-stack-1.png"
            alt="Design image"
          />
        </Flex>
      </Container>

      <Container
        variant="container800"
        sx={{
          pt: [5, 6],
          pb: 5,
          color: "white",
          textAlign: "center"
        }}
      >
        <Heading as="h3" variant="styles.h3">
          Our Services
        </Heading>
        <ServiceCardList
          sx={{
            mt: 4
          }}
        />
        <Link
          to="/services"
          sx={{
            ...theme?.links?.arrow,
            mt: 3,
            display: "inline-block",
            fontSize: 3,
            color: "white"
          }}
        >
          Learn more
        </Link>
      </Container>

      {/* Move your brand card */}
      <MoveYourBrandCard
        sx={{
          m: "0 auto",
          position: "relative",
          zIndex: 2,
          bottom: -5
        }}
      />
    </section>
  )
}

export default HomepageGradientSection
