/** @jsx jsx */
import { jsx, Container, Flex, Box, Heading } from 'theme-ui';

const HomepageHero = () => {
  return (
    <section
      sx={{
        height: ['695px', '880px'],
        position: 'relative',
        color: 'white',
      }}
    >
      {/* Hero image */}
      <Box
        sx={{
          width: '100%',
          height: '100%',
          zIndex: 'back',
          overflow: 'hidden',
        }}
      >
        <video
          autoPlay
          muted
          loop
          playsInline
          poster="/video-poster.jpg"
          sx={{
            bottom: '0',
            height: '100%',
            left: '0',
            objectFit: 'cover',
            position: 'absolute',
            right: '0',
            top: '0',
            width: '100%',
          }}
        >
          <source
            src={
              'https://player.vimeo.com/progressive_redirect/playback/684815169/rendition/1080p?loc=external&signature=748d2c9be72369c4c3581f053086915f2a2ceb5723b085b18856c8827d710798'
            }
            type="video/mp4"
          />
          <source
            src={
              'https://player.vimeo.com/progressive_redirect/playback/684815169/rendition/1080p?loc=external&signature=748d2c9be72369c4c3581f053086915f2a2ceb5723b085b18856c8827d710798'
            }
            type="video/ogg"
          />
        </video>
      </Box>
    </section>
  );
};

export default HomepageHero;
