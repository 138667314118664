/** @jsx jsx */
import { jsx } from 'theme-ui';
import { graphql } from 'gatsby';

import Layout from 'components/Common/Layout';
import HomepageHero from 'components/homepage/HomepageHero';
import HomepageSideImageSection from 'components/homepage/HomepageSideImageSection';
import HomepageGradientSection from 'components/homepage/HomepageGradientSection';
import Header from 'components/Header/Header';

const IndexPage = ({
  data: {
    strapiHomepage: { SEO },
  },
}) => {
  return (
    <Layout
      headerColor="HOME"
      seo={{
        title: SEO.meta_title,
        description: SEO.meta_description,
        shareImage: SEO.share_image,
      }}
    >
      <HomepageHero />
      <HomepageSideImageSection />
      <HomepageGradientSection />
    </Layout>
  );
};

export default IndexPage;

export const homepageQuery = graphql`
  query Homepage {
    strapiHomepage {
      SEO {
        meta_title
        meta_description
        share_image {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;
