/** @jsx jsx */
import { jsx, Card, Text } from "theme-ui"
import { theme } from "gatsby-plugin-theme-ui/index"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

interface ServiceCardListProps {
  className?: string
}
const ServiceCardList = ({ className }: ServiceCardListProps) => {
  return (
    <ul
      className={className}
      sx={{
        width: "100%",
        p: 0,
        display: "grid",
        gridTemplateColumns: [
          "repeat(auto-fill, 157px)",
          "repeat(auto-fill, 184px)"
        ],
        columnGap: 3,
        rowGap: 4,
        justifyContent: "center",
        listStyleType: "none",
        li: {
          color: "themeBlack"
        }
      }}
    >
      <li>
        <Link to="/services#market-research" sx={theme?.links?.serviceCard}>
          <Card variant="service">
            <StaticImage
              src="../../images/service-cards/market.png"
              alt="Design image"
            />
            <Text variant="text.body">Market Research</Text>
          </Card>
        </Link>
      </li>
      <li>
        <Link to="/services#branding" sx={theme?.links?.serviceCard}>
          <Card variant="service">
            <StaticImage
              src="../../images/service-cards/branding.png"
              alt="Design image"
            />
            <Text variant="text.body">Branding</Text>
          </Card>
        </Link>
      </li>
      <li>
        <Link to="/services#seo" sx={theme?.links?.serviceCard}>
          <Card variant="service">
            <StaticImage
              src="../../images/service-cards/seo.png"
              alt="Design image"
            />
            <Text variant="text.body">SEO</Text>
          </Card>
        </Link>
      </li>
      <li>
        <Link to="/services#social-media" sx={theme?.links?.serviceCard}>
          <Card variant="service">
            <StaticImage
              src="../../images/service-cards/social-media.png"
              alt="Design image"
            />
            <Text variant="text.body">Social Media</Text>
          </Card>
        </Link>
      </li>
      <li>
        <Link
          to="/services#pay-per-click-campaigns"
          sx={theme?.links?.serviceCard}
        >
          <Card variant="service">
            <StaticImage
              src="../../images/service-cards/pay-per-click.png"
              alt="Design image"
            />
            <Text variant="text.body">Pay Per Click Campaigns</Text>
          </Card>
        </Link>
      </li>
      <li>
        <Link
          to="/services#branded-content-development"
          sx={theme?.links?.serviceCard}
        >
          <Card variant="service">
            <StaticImage
              src="../../images/service-cards/branded-content.png"
              alt="Design image"
            />
            <Text variant="text.body">Branded Content Development</Text>
          </Card>
        </Link>
      </li>
      <li>
        <Link
          to="/services#advertisement-strategy-development"
          sx={theme?.links?.serviceCard}
        >
          <Card variant="service">
            <StaticImage
              src="../../images/service-cards/strategy.png"
              alt="Design image"
            />
            <Text variant="text.body">Strategy Development</Text>
          </Card>
        </Link>
      </li>
      <li>
        <Link
          to="/services#entertainment-services"
          sx={theme?.links?.serviceCard}
        >
          <Card variant="service">
            <StaticImage
              src="../../images/service-cards/entertainment.png"
              alt="Design image"
            />
            <Text variant="text.body">Entertainment Services</Text>
          </Card>
        </Link>
      </li>
    </ul>
  )
}

export default ServiceCardList
